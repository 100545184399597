<template>
    <div class="container" v-loading="loading">
        <back />
        <div class="job-list" v-if="!loading">
            <div class="dept-name">
                五悦云各部门工作汇总
            </div>
            <div class="head-box">
                <el-select v-model="jobTimeId" placeholder="请选择时间" style="width: 260px;" @change="getList">
                    <el-option v-for="item in timeList" :key="item.id" :label="item.desc" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="job-table">
                <el-card class="box-card" v-for="(dept, index) in list" :key="index" v-show="dept.jobList.length > 0"
                    style="margin-top: 20px;">
                    <div slot="header" class="clearfix" style="text-align: center">
                        <span style="font-size: 24px;font-weight: bold;">{{ dept.deptInfo.name }}</span>
                    </div>
                    <div v-for="(job, y) in dept.jobList" :key="job.id" style="margin-top: 10px;font-family: '黑体';">
                        <span style="font-weight: bold;">{{ y + 1 }}</span>.
                        <span style="font-weight: bold;font-size: 20px;">
                            {{ job.title }}
                            <span v-if="job.people">({{ job.people }})</span>
                            :
                        </span>
                        <span style="">
                            {{ job.content }}
                        </span>
                    </div>
                </el-card>
            </div>
        </div>
    </div>
</template>

<script>
import {
    jobTimeList,
    jobCollect
} from "@/api";
import back from "@/components/back";
export default {
    components: {
        back,
    },
    data() {
        return {
            loading: true,
            list: [],
            jobTimeId: null,
            timeList: [],
        }
    },
    methods: {
        async getList() {
            await jobCollect({
                jobTimeId: this.jobTimeId
            }).then(res => {
                console.log(res);
                this.list = res.data
            })
        },
        async getJobTimeList() {
            await jobTimeList().then(res => {
                console.log(res);
                this.timeList = res.data
                this.jobTimeId = res.data[0].id
            })
        },
    },
    async created() {
        await this.getJobTimeList()
        await this.getList()
        this.loading = false
    }
}
</script>

<style lang="scss">
.el-table__header-wrapper {
    .el-table__cell {
        background-color: #dbeeff !important;
        color: #000000 !important;
        border: 1px solid rgba(0, 0, 0, .2) !important;
        height: 60px;
        font-size: 18px;
    }
}
</style>
<style lang="scss" scoped>
.container {
    background-size: 1000%;
    animation: bganimation 60s infinite;
    background-attachment: fixed;
    background-image: linear-gradient(125deg,
            #e4ffcd,
            #6dd5fa,
            rgba(34, 209, 113, 0.3),
            #e4ffcd,
            #c7f1e7,
            #b9cfff);
    width: 100vw;
    height: 100vh;

    .job-list {
        overflow: auto;
        width: 80%;
        height: 80%;
        padding: 20px;
        position: absolute;
        background-color: rgba(228, 239, 255, 0.7);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);

        .dept-name {
            font-size: 26px;
            font-weight: bold;
            height: 6%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .head-box {
            width: 100%;
            padding: 0 0 10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            border-bottom: 1px solid rgba(0, 0, 0, .3);
        }

        .job-table {
            width: 100%;
            padding: 0 0 10px;
        }
    }
}
</style>